import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericModalComponent } from 'src/app/shared/components/generic-modal/generic-modal.component';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { CareLinePriorityService } from 'src/app/shared/services/API/care-line-priority/care-line-priority.service';
import { LookupCareLinePriorityService } from 'src/app/shared/services/API/care-line-priority/lookup-care-line-priority.service';
import { ListHealthUnitService } from 'src/app/shared/services/API/user/list-health-unit.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks } from 'src/app/shared/services/mask.service';
import { CareLinePriorityIcon } from 'src/app/shared/services/models/care-line-priority/care-line-priority-icon.model';
import { NextStepsAfterTriageProtocol } from 'src/app/shared/services/models/care-line-priority/next-steps-after-triage-procotol.model';
import { CareLinePriorityRequest } from 'src/app/shared/services/requests/care-line-priority/care-line-priority.request';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';

@Component({
  selector: 'app-care-line-priority-register',
  templateUrl: './care-line-priority-register.component.html',
  styleUrls: ['./care-line-priority-register.component.css']
})
export class CareLinePriorityRegisterComponent implements OnInit, OnDestroy {

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private careLinePriorityService: CareLinePriorityService,
    private lookupCareLinePriorityService: LookupCareLinePriorityService,
    private healthUnitService: ListHealthUnitService,
    public dialog: MatDialog,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.care_line_priority;
  public model: FormGroup;
  public isLoading: boolean = true;
  public isFirstLoading: boolean = true;
  public isUpdate: boolean = false;
  public maxLengthNextSteps: number = 100;
  public selectedIconPath: string;
  public searchHealthUnit: string = '';

  public masks: Masks;
  public idCareLinePriority: number = null;
  public careLinePriorityRegisterRequest: CareLinePriorityRequest = new CareLinePriorityRequest();

  public listHealthUnit: ListHealthUnitStruct[] = [];
  public nextStepsAfterTriageProtocolStruct: NextStepsAfterTriageProtocol = new NextStepsAfterTriageProtocol();
  public listIcons: CareLinePriorityIcon[] = [];
  public isDefault: boolean = false;
  public actualSelectedIcon: CareLinePriorityIcon;
  public timeoutIcon;

  ngOnInit(): void {
    this.populateHealthUnitSelect();

    this.model = this.formBuilder.group({
      careLinePriorityName: ['', [Validators.required]],
      iconClass: ['', [Validators.required]],
      idCareLinePriorityIcon: ['', [Validators.required]],
      firstChildGroup: this.formBuilder.group({
        listIdHealthUnit: ['', [Validators.required]],
      }),
      isActive: ['', [Validators.required]],
      openProtocolTriage: [false],
      protocolName: [null],
      listNextStepsAfterTriageProtocol: this.formBuilder.array([]),
    });

    if (this.activatedRoute.snapshot.paramMap.get('idCareLinePriority'))
      this.idCareLinePriority = parseInt(this.activatedRoute.snapshot.paramMap.get('idCareLinePriority'));

    if (this.idCareLinePriority != null)
      this.isUpdate = true;

    this.populateIconSelect();
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutIcon);
  }

  submit() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    if (!this.model.valid) {
      this.alertDynamic('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.careLinePriorityRegisterRequest.careLinePriorityName = this.model.get('careLinePriorityName').value;
    this.careLinePriorityRegisterRequest.iconClass = this.model.get('iconClass').value;
    this.careLinePriorityRegisterRequest.idCareLinePriorityIcon = this.model.get('idCareLinePriorityIcon').value;
    this.careLinePriorityRegisterRequest.listIdHealthUnit = this.model.get('firstChildGroup').get('listIdHealthUnit').value;
    this.careLinePriorityRegisterRequest.isActive = this.model.get('isActive').value === "true" ? true : false;
    this.careLinePriorityRegisterRequest.openProtocolTriage = this.model.get('openProtocolTriage').value;
    this.careLinePriorityRegisterRequest.protocolName = this.model.get('protocolName').value;

    this.model.get('listNextStepsAfterTriageProtocol').value.forEach(x => {
      var nextStepsAfterTriageProtocolStruct = new NextStepsAfterTriageProtocol();

      nextStepsAfterTriageProtocolStruct.idNextStepsAfterTriageProtocol = x.idNextStepsAfterTriageProtocol;
      nextStepsAfterTriageProtocolStruct.nextStepsAfterTriageProtocolDescription = x.nextStepsAfterTriageProtocolDescription;

      this.careLinePriorityRegisterRequest.listNextStepsAfterTriageProtocolStruct.push(nextStepsAfterTriageProtocolStruct);
    });

    if (this.isUpdate)
      this.updateCareLinePriority();
    else
      this.createCareLinePriority();
  }

  populateCareLinePriorityData() {
    this.careLinePriorityService.getCareLinePriority(this.idCareLinePriority).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.model.get('careLinePriorityName').setValue(response.careLinePriorityStruct.careLinePriority.careLinePriorityName);
        this.model.get('iconClass').setValue(response.careLinePriorityStruct.careLinePriority.iconClass);
        this.model.get('idCareLinePriorityIcon').setValue(response.careLinePriorityStruct.careLinePriority.idCareLinePriorityIcon);
        this.model.get('firstChildGroup').get('listIdHealthUnit').setValue(response.careLinePriorityStruct.listIdHealthUnit);
        this.model.get('isActive').setValue(response.careLinePriorityStruct.careLinePriority.isActive.toString());
        this.model.get('openProtocolTriage').setValue(response.careLinePriorityStruct.careLinePriority.openProtocolTriage);
        this.model.get('protocolName').setValue(response.careLinePriorityStruct.careLinePriority.protocolName);
        this.actualSelectedIcon = this.listIcons.find(x => x.iconClass == response.careLinePriorityStruct.careLinePriority.iconClass);

        this.isDefault = this.actualSelectedIcon.isDefaultIcon;
        this.selectedIconPath = `assets/icons/${this.actualSelectedIcon.iconFileName}`;

        if (this.model.get('openProtocolTriage').value == true) {
          this.model.get('protocolName').setValidators(Validators.required);
          this.model.get('protocolName').updateValueAndValidity();
        }
        response.careLinePriorityStruct.listNextStepsAfterTriageProtocol.forEach(x => {
          (this.model.controls['listNextStepsAfterTriageProtocol'] as FormArray).push(
            this.formBuilder.group({
              idNextStepsAfterTriageProtocol: [x.idNextStepsAfterTriageProtocol.toString()],
              nextStepsAfterTriageProtocolDescription: [x.nextStepsAfterTriageProtocolDescription, Validators.required],
              idCareLinePriority: [x.idCareLinePriority],
            })
          )
        });

        this.model.get('careLinePriorityName').disable();

        this.endLoading();
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateCareLinePriority() {
    this.careLinePriorityService.updateCareLinePriority(this.idCareLinePriority, this.careLinePriorityRegisterRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.alertDynamic('Sucesso', "Informações salvas com sucesso", AlertType.success);

        this.router.navigate(['/care-line-priority']);
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createCareLinePriority() {
    this.careLinePriorityService.createCareLinePriority(this.careLinePriorityRegisterRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          if (response.errorCode != 2)
            return;
        }

        this.alertDynamic('Sucesso', "Informações salvas com sucesso", AlertType.success);

        this.router.navigate(['/care-line-priority']);
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateHealthUnitSelect() {
    this.healthUnitService.listHealthUnit(this.searchHealthUnit, null).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        const newList: ListHealthUnitStruct[] = response.list.map(item => ({
          idHealthUnit: item.idHealthUnit,
          healthUnitName: item.healthUnitName,
        }));

        const selectedItems = this.listHealthUnit.filter(item =>
          this.model.get('firstChildGroup').get('listIdHealthUnit')?.value.includes(item.idHealthUnit)
        );

        const combinedList = [...selectedItems, ...newList.filter(item =>
          !selectedItems.some(selected => selected.idHealthUnit === item.idHealthUnit)
        )];

        this.listHealthUnit = combinedList;

        this.endLoading();
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    this.searchHealthUnit = event;
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.populateHealthUnitSelect();
      }
    }, 1000);
  }

  populateIconSelect() {
    this.lookupCareLinePriorityService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listIcons = response.listCareLinePriorityIcon;
        this.listIcons.forEach(element => {
          element.completeFileSrc = `assets/icons/${element.iconFileName}`;
        });
        if (this.idCareLinePriority != null)
          this.populateCareLinePriorityData();
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  setIsDefaultIcon(event) {
    this.selectedIconPath = `assets/icons/${event.value.iconFileName}`;
    this.isDefault = event.value.isDefaultIcon;
    this.model.get('iconClass').setValue(event.value.iconClass)
    this.model.get('idCareLinePriorityIcon').setValue(event.value.idCareLinePriorityIcon)
  }

  addNext() {
    (this.model.controls['listNextStepsAfterTriageProtocol'] as FormArray).push(this.createInput());
  }

  createInput() {
    return this.formBuilder.group({
      nextStepsAfterTriageProtocolDescription: ['', [Validators.required, Validators.max(100)]]
    });
  }

  removeButton(index: number) {
    if (index != 0)
      (this.model.controls['listNextStepsAfterTriageProtocol'] as FormArray).removeAt(index);
  }

  changeMode(event: any) {
    if (!event.checked) {
      const dialogRef = this.dialog.open(GenericModalComponent, {
        data: {
          isTwoButtonsModal: true,
          title: `Atenção!`,
          description: `Tem certeza que deseja inativar a abertura do protocolo? Esta ação é irreversível`,
          hasImage: false
        },
      });
      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (result && result.confirm) {
            (this.model.controls['listNextStepsAfterTriageProtocol'] as FormArray).clear();
            this.model.get('openProtocolTriage').setValue(event.checked);
            this.model.get('protocolName').setValidators(null);
            this.model.get('protocolName').setValue(null);
            this.model.get('protocolName').updateValueAndValidity();
          }
          else
            this.model.get('openProtocolTriage').setValue(!event.checked);
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
    else {
      this.model.get('openProtocolTriage').setValue(event.checked);
      this.model.get('protocolName').setValue(null);
      this.model.get('protocolName').setValidators(Validators.required);
      this.model.get('protocolName').updateValueAndValidity();
      this.addNext();
    }
  }

  alertDynamic(alertTypeDescription: string, alertDescription: string, alertType: AlertType) {
    if (alertType && alertType.valueOf() == AlertType.error)
      console.log(alertDescription);

    this.alertService.show(alertTypeDescription, alertDescription, alertType ? alertType.valueOf() : AlertType.error);
    this.endLoading();
  }

  endLoading() {
    this.isFirstLoading = false;
    this.isLoading = false;
  }
} 