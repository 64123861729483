export class PostPutBatchConfigurationRequest {
    public projectName: string;

    public timeCourseHour: number;

    public datetimeInit: Date;

    public subscribe: boolean;

    public isCritical: boolean;
}