<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list" *ngIf="data.hasImage">
            <div class="img-head">
                <img [src]="img" style="width: 5rem;" />
            </div>
            <div class="title-modal">
                <h1>{{title}}</h1>
            </div>
            <div class="body-modal">
                {{description}}
            </div>
        </div>
        <div *ngIf="!data.hasImage">
            <div class="title-modal">
                <h1>{{title}}</h1>
            </div>
            <div class="body-modal">
                {{description}}
            </div>
        </div>
        <div *ngIf="!data.isTwoButtonsModal && !data.hasImage" class="btn-container">
            <button mat-flat-button color="primary" class="button-close-modal"
                (click)="close()">{{textButtonCancel}}</button>
        </div>
        <div *ngIf="!data.isTwoButtonsModal && data.hasImage" class="btn-container">
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button color="primary" class="button-close-modal-other"
                        (click)="close()">{{textButtonCancel}}</button>
                </div>
            </div>
        </div>
        <div *ngIf="this.data.isTwoButtonsModal" class="btn-container">
            <button mat-flat-button class="btn-primary btn-block"
                (click)="confirm()">{{this.textButtonConfirm}}</button>
            <button mat-flat-button class="btn-secundary block"
                (click)="cancel()">{{this.textButtonCancel}}</button>
        </div>
    </div>
</div>